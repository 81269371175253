<template>
<div>
    <b-card no-body>
        <b-card-header class="pb-50">
            <h5>
                Filtreleme
            </h5>
        </b-card-header>
        <b-card-body>
            <b-row>

                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <label>Sınıf/Ders</label>
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedLesson" :options="lessons" class="w-100" :getOptionLabel="option => option.class.name+' / '+option.lesson.name" :reduce="val => val.id" />
                </b-col>

                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <label>Alt Ders</label>
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedChildLesson" :options="childLessons" class="w-100" :reduce="val => val.id" label="name" />
                </b-col>

                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <label>Konu</label>
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedTopic" :options="topics" class="w-100" :reduce="val => val.id" label="name" />
                </b-col>

                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <label>Video Grup</label>
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedVideoGroup" :options="videoGroups" class="w-100" :reduce="val => val.id" label="name" />
                </b-col>

                <b-col cols="12" md="12" class="mb-md-0 mb-2">
                    <b-button variant="success" @click="getTestData()" style="margin-top:10px;width:100%">FİLTRELE</b-button>
                </b-col>

            </b-row>
            <b-overlay :show="loading" no-wrap>
            </b-overlay>
        </b-card-body>
    </b-card>

    <b-card v-if="users" no-body class="card-company-table">
        <b-table :items="users" responsive :fields="fields" class="mb-0">
            <!-- Id -->
            <template #cell(id)="data">
                <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
            </template>

            <template #cell(text)="data">
                <b-button block variant="flat-primary" class="font-weight-bolder mb-12" @click="data.toggleDetails">{{data.item.text }}</b-button>
            </template>

            <template #cell(video)="data">
                <b-badge pill>
                    {{ data.item.video!=null?data.item.video.name:'Herhangi Bir Videoya Atanmamış' }}
                </b-badge>
            </template>

            <!-- Id -->
            <template #cell(question)="data">
                <span class="font-weight-bolder mb-12">{{
                    (data.item.question!=null)?
                    data.item.question.length+' Adet Soruya Atandı':
                    "Hiç Bir Soruya Atanmadı"
                    }}</span>
            </template>

            <template #row-details="data">
                <b-card>
                    <b-card-body>
                        <b-table :items="data.item.question" responsive :fields="userSuccessFields" class="mb-0">
                            <template #cell(action)="data">
                                <a target="_blank" :href="'https://board.turkiyeakademi.net/?referance='+data.item.question.questionFile.id" class="font-weight-bolder mb-12">Aç</a>
                            </template>
                        </b-table>
                    </b-card-body>
                </b-card>
            </template>

        </b-table>
    </b-card>

</div>
</template>

<script>
import {
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BProgress,
    BOverlay
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
export default {
    components: {
        ToastificationContent,
        BCardHeader,
        BCardBody,
        BRow,
        BCol,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup,
        vSelect,
        BProgress,
        BOverlay
    },
    data() {
        return {
            loading: false,
            fields: [
                { key: 'id', label: 'Ref' },
                { key: 'code', label: 'Kazanım Kodu' },
                { key: 'text', label: 'Kazanım' },
                { key: 'video', label: 'Atanan Video' },
                { key: 'time', label: 'Atanan Süre' },
                { key: 'question', label: 'Atanan Sorular' },
            ],
            status: [
                { key: 2, label: 'Devam Ediyor' },
                { key: 4, label: 'Sonuçlar İşleniyor' },
                { key: 5, label: 'Sonuçlandı' },
            ],
            userSuccessFields: [
                { key: 'test.title', label: 'Testin Adı' },
                { key: 'question.viewOrder', label: 'Soru Numarası' },
                { key: 'action', label: "Soru Resmi" }
            ],
            selectedLesson: null,
            selectedChildLesson: null,
            selectedTopic: null,
            selectedVideoGroup: null,
            selectedTest: null,
            users: [],
            lessons: [],
            childClass: [],
            childLessons: [],
            videoGroups: [],
            topics: [],
            tests: [],
            filterRequest: {
                lessnId: '',
                childClassId: '',
                childLessonId: '',
                status: 4
            },
            ort: {
                totalOrt: 0,
                correct: 0,
                wrong: 0,
                empty: 0,
                point: 0
            }
        }
    },
    created() {
        this.getLesson();
    },
    watch: {
        selectedLesson: function (val) {
            this.getChildLesson();
            this.childClass = [];
            this.filterRequest.childClassId = null;
            if (val != null) {
                var filterData = this.lessons.filter(x => x.id == val)[0];
                this.childClass = filterData.childClass;
            }
        },
        selectedChildLesson: function () {
            this.getTopics();
        },
        selectedTopic: function () {
            this.getVideoGroup();
        },
        selectedVideoGroup: function () {
            this.getTests();
        }
    },
    methods: {

        async getLesson() {
            var lesson = await this.$http.get("Teacher/Lessons");
            this.lessons = lesson.data.data;
        },

        async getChildLesson() {
            this.filterRequest.childLessonId = "";
            var data = this.lessons.filter(x => x.id == this.selectedLesson);
            if (data.length > 0) {
                data = data[0];
                var childLesson = await this.$http.get("Teacher/ChildLessons/" + data.lesson.id + "/" + data.class.id);
                this.childLessons = childLesson.data.data;
            }
        },

        async getTopics() {
            var topics = await this.$http.get("Teacher/Topics/" + this.selectedChildLesson);
            this.topics = topics.data.data;
        },

        async getVideoGroup() {
            var videoGroups = await this.$http.get("Teacher/VideoGroup/" + this.selectedTopic);
            this.videoGroups = videoGroups.data.data;
        },

        async getTests() {
            var tests = await this.$http.get("Teacher/Test/" + this.selectedVideoGroup);
            this.tests = tests.data.data;
        },

        async getTestData() {
            var data = this.lessons.filter(x => x.id == this.selectedLesson);
            if (data.length > 0) {
                this.loading = true;
                data = data[0];

                var request = {
                    lessonId: data.lesson.id,
                    classId: "00000000-0000-0000-0000-000000000000",
                    childLessonId: "00000000-0000-0000-0000-000000000000",
                    topicId: "00000000-0000-0000-0000-000000000000",
                    videoGroupId: "00000000-0000-0000-0000-000000000000",
                };

                if (this.filterRequest.childClassId != null) { request.classId = this.filterRequest.childClassId; }
                if (this.selectedChildLesson != null) { request.childLessonId = this.selectedChildLesson; }
                if (this.selectedTopic != null) { request.topicId = this.selectedTopic; }
                if (this.selectedVideoGroup != null) { request.videoGroupId = this.selectedVideoGroup; }

                var lesson = await this.$http.post("Report/Gains", request);
                this.users = lesson.data.data;
                //this.getCalculator();
                this.loading = false;
            }
        },

        getQuestionCount() {
            var testQuestion = [];
            this.users.forEach(element => {
                var testtt = testQuestion.filter(x => x.id == element.testId);
                if (testtt == null || testtt.length == 0) {
                    testQuestion.push({
                        id: element.testId,
                        count: element.correct + element.wrong + element.empty,
                        title: element.title
                    });
                }
            });
            return testQuestion;
        },

        getCalculator() {

            var correctOrt = 0;
            var emptyOrt = 0;
            var wrongOrt = 0;
            var pointOrt = 0;
            var totalOrt = 0;
            var qstr = this.getQuestionCount();

            this.users.forEach(element => {
                correctOrt += element.correct;
                emptyOrt += element.empty;
                wrongOrt += element.wrong;
                pointOrt += element.point;
                totalOrt += qstr.filter(x => x.id == element.testId)[0].count
            });

            this.ort.totalOrt = (totalOrt / this.users.length).toFixed(2);
            this.ort.correct = (correctOrt / this.users.length).toFixed(2);
            this.ort.empty = (emptyOrt / this.users.length).toFixed(2);
            this.ort.wrong = (wrongOrt / this.users.length).toFixed(2);
            this.ort.point = (pointOrt / this.users.length).toFixed(2);

        },

        getExamStatus(status) {
            switch (status) {
                case 'Open':
                    return 'primary'
                case 'Waiting':
                    return 'info'
                case 'Closed':
                    return 'secondary'
                case 'Processing':
                    return 'warning'
                case 'HasResulted':
                    return 'success'
            }
        },

        getExamStatusText(status) {
            switch (status) {
                case 'Open':
                    return "Başlamadı"
                case 'Waiting':
                    return "Devam Ediyor"
                case 'Closed':
                    return "Kapalı"
                case 'Processing':
                    return "Sonuçlar İşleniyor"
                case 'HasResulted':
                    return "Sonuçlandı"
            }
        }

    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
